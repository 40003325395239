//切换主题色
export const TheThemeColor = "blue"; //purple
//默认按钮设置
export const DefalutButton = {
  "Email Me": {
    tag: "email",
    text: "tell me what is your Email!",
  },
  "Call Me": {
    tag: "phone",
    text: "tell me what is your Phone!",
  },
};
// ["Email Me", "Call Me"]
//设置主题色
export const BgColorStore = {
  blue: "#3776F4",
  purple: "#4C2074",
};
//设置聊天框渐变色
export const ChatBgColorStore = {
  blue: "linear-gradient(180deg, #E5F1FF 0%, #FFFFFF 100%)",
  purple: "linear-gradient(180deg, #F0E5FF 0%, rgba(255,255,255,0.00) 100%)",
};
export const ChatBgHeaderColorStore = {
  blue: "",
  purple: "linear-gradient(180deg, #F0E5FF -3%, rgba(255,255,255,0.00) 100%)",
};

export const NoContent =
  "I'm sorry, but I cannot understand the question as it appears to be a random sequence of letters and words. Can you please provide a clear";

// // 兼容手机的最大宽度
// export const maxWidth = "480px"
//初始化内容
export const DefaultContent = [
  "Hi there~ I'm ChatBrill, an AI chatbot. With a quick 4-step setup, I'm ready to serve your site's visitors. Fire away with any questions!",
];

export const sendSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    version="1.1"
    width="15"
    height="15"
    viewBox="0 0 14.999791145324707 15.00053596496582"
  >
    <g>
      <path
        d="M9.72233,15.0005C9.70483,15.0005,9.68817,14.9997,9.6715,14.9989C9.29483,14.9764,8.98067,14.703,8.90567,14.333C8.90567,14.333,7.624,8.0272,7.624,8.0272C7.55733,7.69887,7.3015,7.44304,6.97317,7.37637C6.97317,7.37637,0.667334,6.09387,0.667334,6.09387C0.297334,6.0197,0.0240003,5.70554,0.00150026,5.32887C-0.0209998,4.95137,0.2115,4.60637,0.569834,4.48804C0.569834,4.48804,13.9032,0.0438694,13.9032,0.0438694C14.2023,-0.0577973,14.5323,0.0205361,14.7557,0.244703C14.979,0.468036,15.0565,0.798036,14.9573,1.0972C14.9573,1.0972,10.5123,14.4305,10.5123,14.4305C10.399,14.773,10.079,15.0005,9.72233,15.0005Z"
        fillRule="evenodd"
        fill="blue"
        fillOpacity="1"
      />
    </g>
  </svg>
);

export const PowerBy = "Powered By ChatBrill";
export const errorHTML = (
  <>
    Oh, I'm truly sorry, it seems there's an issue with my server! Please
    contact the website's customer service or help me get in touch with my
    provider, ChatBrill, at{" "}
    <a href="https://www.chatbrill.com" target="_blank" className="chatbrill-a">
      hi@chatbrill.com
    </a>
    . I would greatly appreciate your assistance.
  </>
);
export const emailSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    width="20px"
    height="18px"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
    />
  </svg>
);
export const phoneSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    width="20px"
    height="18px"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
    />
  </svg>
);
export const Waiting = "Waiting...|";
export const NoWaiting = "Waiting....";
export const expiresTime = 60 * 60 * 1000;
export const timeout = 60000;
