import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import router from "./router";
// import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from "react-router-dom";
document.title = "ChatBrill";
const script = document.createElement("script");
script.src = "https://www.googletagmanager.com/gtag/js?id=G-8E3RRMF28Y";
script.async = true;
document.getElementsByTagName("head")[0].appendChild(script);
// document.body.appendChild(script);
const root = ReactDOM.createRoot(document.getElementById("root"));

window.dataLayer = window.dataLayer || [];
function gtag() {
  window.dataLayer.push(arguments);
}
gtag("js", new Date());

gtag("config", "GA_TRACKING_ID");

function reportGAEvent(category, action, label) {
  console.log(category, action, label);
  gtag("event", action, {
    event_category: category,
    event_action: action,
    event_label: label,
  });
}
window.reportGAEvent = reportGAEvent;
root.render(
  // <React.StrictMode>
  <RouterProvider router={router}> </RouterProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
