import { useLayoutEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import "./index.css";
import {
  TheThemeColor,
  BgColorStore,
  sendSvg,
  Waiting,
  NoWaiting,
  errorHTML,
  expiresTime,
  emailSvg,
  phoneSvg,
} from "../constant";
import { Input, message } from "antd";
import { AxiosRequest } from "../request";
import { scrollToElement, getSessionStorage, requestSession } from "../utils";
const pathRes = window.location.pathname.split("/");
const sessionStorageID = "chatbrill_session_" + (pathRes[1] || "common");
function Dialogue(props) {
  const { popLog,chatDom } = props;
  let {
    isBot,
    message: content,
    isPrint,
    isLoding,
    isDefault,
    isError,
  } = props.content;
  const [inputValue, setInputValue] = useState("");
  const contentRef = useRef();
  const writing = () => {
    let timer = null;
    let isTrue = false;

    timer = setInterval(() => {
      if (
        contentRef.current.innerText === Waiting ||
        contentRef.current.innerText === NoWaiting
      ) {
        if (isTrue) {
          contentRef.current.innerHTML = NoWaiting;
        } else {
          contentRef.current.innerHTML = Waiting;
        }
        isTrue = !isTrue;
      } else {
        clearInterval(timer);
      }
    }, 500);
  };
  useLayoutEffect(() => {
    writing();
    // scrollToElement(chatDom);
  }, [isError]);
  window.addEventListener("message", (e) => {
    if (e.data === "chatbrill-popup-open") {
      scrollToElement(contentRef.current);
    }
  });
  const getDefaultComp = () => {
    return (
      <div>
        <div className="mb-2 ">
          <p ref={contentRef}>{`tell me what is your ${content}`}</p>
        </div>
        <Input
          style={{ zIndex: 0 }}
          value={inputValue}
          onChange={(e) => {
            // console.log(e)
            setInputValue(e.target.value);
          }}
          placeholder={content === "email" ? "email address" : "phone number"}
          prefix={content === "email" ? emailSvg : phoneSvg}
          suffix={
            <button
              onClick={async () => {
                let sessionID = getSessionStorage(sessionStorageID);
                if (!sessionID) {
                  sessionID = await requestSession(1);
                }
                AxiosRequest(`/api/api/chat`, {
                  method: "post",
                  data: {
                    chatbotid: parseInt(pathRes[1]) || "chatbrill-script",
                    inputType: content.toLocaleUpperCase,
                    sessionid: sessionID,
                    inputValue: inputValue,
                  },
                }).then((res) => {
                  console.log(res);
                  if (res && res?.status === 200) {
                    popLog(content);
                    setInputValue("");
                    message.open({
                      type: "success",
                      content: "Submitted successfully!",
                      // durati 1on:20
                    });
                    sessionStorage.setItem(
                      sessionStorageID,
                      JSON.stringify({
                        sessionID: sessionID,
                        expires: dayjs().valueOf() + expiresTime,
                      })
                    );
                  } else {
                    message.open({
                      type: "error",
                      content: "Submission failed!",

                      // durati on:20
                    });
                  }
                });
              }}
            >
              {sendSvg}
            </button>
          }
        ></Input>
      </div>
    );
  };

  return (
    <div
      className={[
        "flex",
        isBot ? "justify-start" : "justify-end",
        "mr-8",
        // "items-center",
      ].join(" ")}
    >
      {isBot ? (
        <div className="flex items-center justify-center w-[42px] h-[42px] rounded-[21px]  max-w-prose mb-3  py-3 px-4 mr-3">
          <div className="">
            <img
              alt="profile picture"
              src={require("../static/chat.png")}
              decoding="async"
              className="max-w-[50px] sm:w-[42px] w-[30px]"
            />
          </div>
        </div>
      ) : null}
      <div
        className=" overflow-auto max-w-prose mb-3 rounded-lg py-3 px-4"
        style={{
          backgroundColor: isBot ? "#FFFFFF" : BgColorStore[TheThemeColor],
          boxShadow: isBot
            ? "5px 5px 20px 0px rgba(181, 181, 181, 0.3)"
            : "5px 5px 20px 0px rgba(55, 118, 244, 0.3)",
          color: isBot ? "black" : "white",
          borderRadius: isBot ? "0px 10px 10px 10px" : "10px 0px 10px 10px",
        }}
      >
        <div className="flex flex-col items-start gap-4 break-words">
          {isDefault ? (
            getDefaultComp()
          ) : (
            <div className="prose text-inherit text-left w-full break-words dark:prose-invert ">
              <span ref={contentRef} className="chatbrill-span">
                {isError ? errorHTML : content}
              </span>
           
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dialogue;
