import aioxs from "axios";

import { fetchEventSource } from "@microsoft/fetch-event-source";

const { pathname } = window.location;
export const AxiosRequest = aioxs.create({
  // 后台接口的基准地址
  // baseURL: "http://3.85.120.79:3000/",
  // baseURL: "/api",
  timeout: 10000,
});

// 添加请求拦截器
AxiosRequest.interceptors.request.use(
  (config) => {
    console.log("请求时间", new Date());
    config.headers.token = pathname.substring(1) || "";
    return config;
  },
  function (error) {
    console.log("请求错误时间", new Date());
    //对相应错误做点什么
    return Promise.reject(error);
  }
);

//拦截器响应
AxiosRequest.interceptors.response.use(
  (response) => {
    console.log("响应时间", new Date());
    return response;
  },
  function (error) {
    //对相应错误做点什么
    console.log("响应超时时间", new Date());
    // alert(error.message)
  }
);

export const FetchPostRequest = function (url, params) {
  return Promise.race([
    fetchEventSource(url, { ...params }),
    new Promise((resovle, reject) => {
      setTimeout(() => {
        reject(new Error("请求超时"));
      }, 10000 );
    }),
  ]);
};
