import { useState, useRef, useLayoutEffect, useCallback, useMemo, useEffect } from "react";
import Dialogue from "./components/dialogue";

import dayjs from "dayjs";
import {
  TheThemeColor,
  DefalutButton,
  ChatBgColorStore,
  BgColorStore,
  DefaultContent,
  NoContent,
  errorHTML,
  Waiting,
  expiresTime,
  timeout,
} from "./constant";
import {
  scrollToElement,
  getSessionStorage,
  requestSession,
  scrollToElementNew,
} from "./utils";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import "./App.css";
import { AxiosRequest } from "./request";
const pathRes = window.location.pathname.split("/");

const localStorageID = "chatbrill_history_" + (pathRes[1] || "common");
const sessionStorageID = "chatbrill_session_" + (pathRes[1] || "common");

function ChatbotDemo() {
  const history = getLocalStorage();

  const [render, setRender] = useState(false);
  const historyChatlogRef = useRef(history);
  const [chatContent, setChatContent] = useState("");
  const [isRequest, setIsRequest] = useState(false);
  const chatRef = useRef();
  const sonRef = useRef(null);
  const defaultInput = Object.keys(DefalutButton).map((item, index) => {
    return (
      <button
        type="button"
        className={`m-2 py-2 px-3 text-sm rounded-[8px] w-[160px] border bg-white hover:bg-blue hover:text-white text-blue`}
        style={{
          borderColor: BgColorStore[TheThemeColor],
        }}
        onClick={() => {
          clickDefault(DefalutButton[item].tag);
        }}
      >
        {item}
      </button>
    );
  });
  const settingChatLog = async () => {
    if (chatContent !== "") {
      historyChatlogRef.current.push({
        message: chatContent,
        isBot: false,
        role: "user",
        time: dayjs().valueOf(),
      });

      const ctrl = new AbortController();
      setIsRequest(true);

      const initContent = {
        message: Waiting,
        isBot: true,
        role: "chatbot",
        time: dayjs().valueOf(),
      };

      historyChatlogRef.current.push(initContent);
      setRender(!render);
      // scrollToElementNew(chatRef.current)
      let content = "";
      let timer = null;
      let sessionID = getSessionStorage(sessionStorageID);
      if (!sessionID) {
        sessionID = await requestSession(1);
      }
      new Promise((resovle, reject) => {
        timer = setTimeout(() => {
          reject("连接超时");
          ctrl.abort();
        }, timeout);
      }).catch((res) => {
        console.error(res);
        const lastDom = getLastSpanDom();
        initContent.isError = true;
        initContent.message = "The Response is error";
        initContent.time = dayjs().valueOf();
        localStorage.setItem(
          localStorageID,
          JSON.stringify(historyChatlogRef.current)
        );
        setRender(!!render);
        setIsRequest(false);
        // scrollToElement(lastDom);
        scrollToElement(chatRef.current);
      });

      let isTimeOut = false;
      fetchEventSource("/api/api/chat", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          inputType: "MESSAGE",
          inputValue: chatContent,
          chatbotid: pathRes[1] || "chatbrill-script",
          messages: historyChatlogRef.current.slice(
            0,
            historyChatlogRef.current.length - 1
          ),
          sessionid: sessionID,
        }),
        signal: ctrl.signal,
        onopen: (e) => {
          console.log("请求已建立连接!", new Date());
          sessionStorage.setItem(
            sessionStorageID,
            JSON.stringify({
              sessionID: sessionID,
              expires: dayjs().valueOf() + expiresTime,
            })
          );
        },
        onclose: (e) => {
          console.log("连接关闭", e, new Date());
          timer && clearTimeout(timer);
          if (!content) {
            content = NoContent;
          }
          initContent.message = content;
          initContent.time = dayjs().valueOf();
          const lastDom = getLastSpanDom();
          lastDom.innerHTML = content;
          localStorage.setItem(
            localStorageID,
            JSON.stringify(historyChatlogRef.current)
          );
          // setRender(!!render);
          // scrollToElement(lastDom);
          scrollToElement(chatRef.current);
          // scrollToElementNew(chatRef.current)
          setIsRequest(false);
        },

        onmessage: (event) => {
          if (event.data === "[DONE]") {
            console.log("数据已接受完毕!", new Date());
            timer && clearTimeout(timer);
            if (!content) {
              content = NoContent;
            }
            initContent.message = content;
            initContent.time = dayjs().valueOf();
            const lastDom = getLastSpanDom();
            lastDom.innerHTML = content;
            localStorage.setItem(
              localStorageID,
              JSON.stringify(historyChatlogRef.current)
            );
            // setRender(!!render);
            // scrollToElement(lastDom);
            scrollToElement(chatRef.current);
            // scrollToElementNew(chatRef.current)
            setIsRequest(false);
            ctrl.abort();
          } else {
            const data = JSON.parse(event.data);
            if (data?.data === "" && !isTimeOut) {
              isTimeOut = true;
            }
            console.log(data, new Date());
            if (data.sourceDocs) {
            } else if (data.data) {
              timer && clearTimeout(timer);
              const lastDom = getLastSpanDom();
              const result = data.data;
              content += result;
              lastDom.innerHTML = content;
              scrollToElement(chatRef.current);
              // scrollToElementNew(chatRef.current)
            }
          }
        },
      });
    }
  };
  useEffect(() => { scrollToElement(chatRef.current); })
  const clickDefault = (value) => {
    if (isRequest) {
    } else {
      historyChatlogRef.current.push({
        message: value,
        isBot: true,
        isDefault: true,
        time: dayjs().valueOf(),
      });

      setRender(!render);
    }
  };

  const popLog = (log) => {
    const result = historyChatlogRef.current.filter((item) => {
      return item.message !== log;
    });
    historyChatlogRef.current = [...result];
    setRender(!render);
  };

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <div
        className=" flex flex-col min-h-screen sm:text-[16px] text-[14px] leading-[1.75]"
        style={{ background: ChatBgColorStore[TheThemeColor] }}
      >
        <div className="sticky top-0  w-full  z-50">
          <div
            className="flex justify-between py-1  z-10 sm:h-[76px] h-[62px] bg-white sm:bg-[#E5F1FF] mb-4"
            style={{
              borderBottom: "1px solid #f4f4f5",
            }}
          >
            <div className="flex items-center mx-4">
              <img
                alt="profile picture"
                src={require("./static/logo.png")}
                decoding="async"
                // data-nimg="1"
                className="sm:h-[40px] h-[35px]"
              />
            </div>
          </div>
        </div>
        <div className="flex-grow pl-[16px]  pr-[16px]">
          {historyChatlogRef.current.map((item, index) => {

            return (
              <Dialogue
                // ref={chatRef.current}
                content={item}
                key={index}
                popLog={popLog}
              ></Dialogue>
            );
          })}
          <span ref={chatRef}></span>
        </div>
        <div className=" sticky bottom-0 px-4 !bg-white">
          <div className={`py-2 flex justify-start`}>{defaultInput}</div>
          <div
            className="flex  p-2 rounded-[16px] items-center justify-between bg-[#F4F4F4]"
            style={{ border: "1px solid #e4e4e7" }}
          >
            <div className="flex items-center w-11/12">
              <textarea
                aria-label="chat input"
                required=""
                maxLength="1250"
                placeholder="Type a message here"
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && !e.shiftKey) {
                    e.preventDefault();
                    if (!isRequest) {
                      settingChatLog();
                      setChatContent("");
                      window.reportGAEvent("sendMessage", "click", pathRes[1]);
                    }
                  }

                  if (e.keyCode === 13 && e.shiftKey) {
                    e.preventDefault();
                    setChatContent(e.target.value + "\n");
                  }
                }}
                rows="1"
                className=" m-0  min-h-[60px] max-h-36 pr-2 resize-none border-0 bg-inherit flex-1 appearance-none rounded-md focus:ring-0 focus-visible:ring-0 focus:outline-none "
                value={chatContent}
                onChange={(e) => {
                  setChatContent(e.target.value);
                }}
              ></textarea>
            </div>
            <div
              className="flex w-[40px] h-[40px] rounded-[20px] item-center"
              style={{ backgroundColor: BgColorStore[TheThemeColor] }}
            >
              <button
                type="submit"
                className="flex-none pl-[8px] pt-[3px]"
                disabled={isRequest}
                onClick={() => {
                  settingChatLog();
                  setChatContent("");
                  window.reportGAEvent("sendMessage", "click", pathRes[1]);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  version="1.1"
                  width="20"
                  height="20"
                  viewBox="0 0 14.999791145324707 15.00053596496582"
                >
                  <g>
                    <path
                      d="M9.72233,15.0005C9.70483,15.0005,9.68817,14.9997,9.6715,14.9989C9.29483,14.9764,8.98067,14.703,8.90567,14.333C8.90567,14.333,7.624,8.0272,7.624,8.0272C7.55733,7.69887,7.3015,7.44304,6.97317,7.37637C6.97317,7.37637,0.667334,6.09387,0.667334,6.09387C0.297334,6.0197,0.0240003,5.70554,0.00150026,5.32887C-0.0209998,4.95137,0.2115,4.60637,0.569834,4.48804C0.569834,4.48804,13.9032,0.0438694,13.9032,0.0438694C14.2023,-0.0577973,14.5323,0.0205361,14.7557,0.244703C14.979,0.468036,15.0565,0.798036,14.9573,1.0972C14.9573,1.0972,10.5123,14.4305,10.5123,14.4305C10.399,14.773,10.079,15.0005,9.72233,15.0005Z"
                      fillRule="evenodd"
                      fill="#FFFFFF"
                      fillOpacity="1"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div></div>
          <p className="text-center text-xs py-2 " style={{ color: "#3f3f46" }}>
            Powered By &nbsp;
            <a
              target="_blank"
              className="font-semibold"
              style={{ color: "#141410" }}
              href="https://www.chatbrill.com"
            >
              ChatBrill.com
            </a>
          </p>
        </div>
        <div
          aria-live="assertive"
          className="pointer-events-none fixed inset-0 flex items-end px-4 z-20 py-6 sm:items-start sm:p-6"
        >
          <div className="flex w-full flex-col items-center space-y-4 sm:items-end"></div>
        </div>
      </div>
    </div>
  );
}

function getLocalStorage() {
  const history = JSON.parse(localStorage.getItem(localStorageID));
  if (history && history.length > 1) {
    const remain = history.filter((item) => {
      return dayjs().subtract(30, "day").valueOf() < item.time;
    });
    if (remain.length === 0) {
      return DefaultContent.map((item) => {
        return {
          message: item,
          isBot: true,
          role: "chatbot",
          time: dayjs().valueOf(),
        };
      });
    } else {
      return remain.filter((item) => {
        return !item.isDefault;
      });
    }
  } else {
    return DefaultContent.map((item) => {
      return {
        message: item,
        isBot: true,
        role: "chatbot",
        time: dayjs().valueOf(),
      };
    });
  }
}

function getLastSpanDom() {
  const spanDomList = document.querySelectorAll(".chatbrill-span");
  const lastSpanDom = spanDomList[spanDomList.length - 1];
  return lastSpanDom;
}
export default ChatbotDemo;
