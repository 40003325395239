import dayjs from "dayjs";
import { AxiosRequest } from "./request";
export function scrollToElement(ele, mainBody) {
  const rect = ele.getBoundingClientRect();
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
  const top =
    rect.top < 0 ? Math.abs(rect.top) + ele.offsetHeight + scrollTop: rect.top + scrollTop;
  // const top = ele.offsetHeight +scrollTop
  // const left = rect.left + scrollLeft;
  // console.log(ele,"recttop", rect.top, "height",ele.offsetHeight ,"scrollTop", scrollTop);
  window.scrollTo({ top, behavior: "smooth" });
}
export function scrollToElementNew(ele) {
  ele.scrollTop = ele.scrollHeight

}
export function debounce(fn, delay) {
  let time = null; //time用来控制事件的触发
  return function () {
    if (time !== null) {
      clearTimeout(time);
    }
    time = setTimeout(() => {
      fn.call(this);
      //利用call(),让this的指针从指向window 转成指向input
    }, delay);
  };
}

export function getSessionStorage(sessionStorageID) {
  const session = JSON.parse(sessionStorage.getItem(sessionStorageID));
  if (session) {
    const { expires, sessionID } = session;
    //过期了
    if (expires < dayjs().valueOf()) {
      return "";
    } else {
      return sessionID;
    }
  } else {
    return "";
  }
}

export async function requestSession(num) {
  const res = await AxiosRequest("/api/api/getsession", {
    method: "post",
    data: {
      getsession: num,
    },
  });
  // console.log(res);
  if (res && res?.data) {
    console.log("请求新的SessinID", res?.data?.sessionid);
    return res?.data?.sessionid;
  } else {
    return "";
  }
}
